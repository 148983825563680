<template>
  <vxe-modal
    v-if="open"
    v-model="open"
    width="800px"
    height="auto"
    :title="formTitle"
    @close="open = false"
    show-zoom
    resize
    :lock-view="false"
    :mask="true"
    :esc-closable="true"
    :position="{ top: '10%' }"
    remember
    transfer
    ref="manyVehicleMonitorRef"
  >
    <a-form-model ref="form" :model="form" class="send-message">
      <a-row>
        <a-col :md="24" :sm="24">
          <span class="item-title">消息类型：</span>
          <span class="item-content">{{ msgType.typeName }}（{{ msgType.typeCode }}）</span>
        </a-col>
        <a-col :md="12" :sm="24">
          <span class="item-title">消息用户组：</span>
          <span class="item-content">{{ typeChannelConfig.group.groupName }}</span>
        </a-col>
        <a-col :md="12" :sm="24">
          <span class="item-title">发送应用：</span>
          <span class="item-content"> {{ typeChannelConfig.app.appName }}</span>
        </a-col>
        <a-col :md="24" :sm="24">
          <span class="item-title">消息内容：</span>
          <span class="item-content"> {{ template ? template.templateContent : '' }}</span>
        </a-col>
        <a-col :md="24" :sm="24">
          <div class="param-list">
            <div class="row">
              <span class="col header">变量名</span>
              <span class="col header">变量值</span>
            </div>
            <div class="row" v-for="(item, index) in form.paramsList" :key="index">
              <div class="child-row">
                <span class="col">
                  <span class="item-content">{{ item.paramName }}</span>
                </span>
                <span class="col">
                  <a-form-model-item
                    :prop="`paramsList[${index}].paramValue`"
                    :rules="[{ required: true, trigger: 'change', message: '请输入变量值' }]"
                  >
                    <a-input v-model="form.paramsList[index].paramValue" placeholder="请输入变量值" />
                  </a-form-model-item>
                </span>
              </div>
            </div>
          </div>
        </a-col>
      </a-row>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" @click="submitForm"> 发送 </a-button>
          <a-button type="dashed" @click="cancel"> 取消 </a-button>
        </a-space>
      </div>
    </a-form-model>
  </vxe-modal>
</template>

<script>
import { getTemplate } from '@/api/message/template'
import { sendMessageByTypeChannel } from '@/api/message/notification'
export default {
  name: 'SendMessage',
  props: {
  },
  components: {
  },
  data () {
    return {
      loading: false,
      formTitle: '',
      // 表单参数
      form: {
        paramsList: []
      },
      template: null,
      msgType: null,
      typeChannelConfig: null,
      // 1增加,2修改
      formType: 1,
      open: false
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    handleOk () {
      this.open = false
    },
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        paramsList: []
      }
    },
    /** 发送测试按钮操作 */
    handleLoadType (row, msgType) {
      this.msgType = msgType
      this.reset()
      this.typeChannelConfig = row
      this.formType = 2
      this.open = true
      this.formTitle = '发送测试消息'
      getTemplate(row.templateId).then(response => {
        response.data.paramsList.forEach(item => {
          item.paramValue = ''
        })
        this.form.paramsList = response.data.paramsList
        this.template = response.data
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          console.log('this.form >>> ', this.form)
          // return false
          const formData = {
            typeCode: this.msgType.typeCode,
            msgTypeChannelConfigId: this.typeChannelConfig.id,
            templateParams: this.form.paramsList
          }
          sendMessageByTypeChannel(formData).then(response => {
            this.$message.success(
              '发送成功',
              3
            )
            this.open = false
            this.$emit('ok')
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.send-message {
  .item-title {
    font-size: 14px;
    font-weight: bold;
  }
  .item-content {
    margin-left: 10px;
    font-size: 14px;
  }
}

.param-list {
  .title {
    font-size: 16px;
    font-weight: bold;
  }
  .row {
    display: flex;
    .item-delete {
      font-size: 20px;
    }
    .col {
      flex: 1;
      text-align: center;
      border-top: 1px solid #e8e8e8;
      border-left: 1px solid #e8e8e8;
      .ant-form-item-control-wrapper {
        flex: 1;
        widows: 90%;
      }
    }
    .header {
      height: 30px;
      line-height: 30px;
      padding: 0 2px;
    }
    .col.header::before {
      display: inline-block;
      margin-right: 4px;
      color: #f5222d;
      font-size: 14px;
      font-family: SimSun, sans-serif;
      line-height: 1;
      content: '*';
    }
    .col:first-child {
      flex: 1;
      min-width: 280px;
    }
    input {
      border-radius: 10px;
      text-align: center;
    }
    .ant-time-picker {
      border: 0;
    }
    .ant-time-picker-input {
      border: 0 !important;
    }
    .connect-flag {
      border: 0;
      width: 5px;
    }
  }
  .row:last-child {
    .col {
      border-bottom: 1px solid #e8e8e8;
    }
  }
  .child-row {
    width: 100%;
    display: flex;
    .col {
      flex: 1;
      text-align: center;
      vertical-align: middle;
      padding: 3px 4px;
      border-top: 1px solid #e8e8e8;
      border-left: 1px solid #e8e8e8;
      .ant-form-item {
        margin-bottom: 2px;
      }
    }
    .col:first-child {
      flex: 1;
      min-width: 280px;
    }
  }
  .child-row:last-child {
    .col {
      border-bottom: 1px solid #e8e8e8;
    }
  }
}
</style>
